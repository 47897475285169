import axios from "axios";
import setting from "../Constant/setting";
const API_URL = setting.url;

class AuthService {
    login(username, password) {
        return axios
            .post(API_URL + '/api/auth/login', {username, password})
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(username, email, password) {
        return axios.post(API_URL + "signup", {
            username,
            email,
            password,
        });
    }
}

export default new AuthService();
